// extracted by mini-css-extract-plugin
export var meta = "blog-list-module--meta--wPoY8";
export var cardLink = "blog-list-module--cardLink--6vGXq";
export var card = "blog-list-module--card--mPOu1";
export var cardTitle = "blog-list-module--cardTitle--5v6Wz";
export var card1 = "blog-list-module--card1--aCYgS";
export var card2 = "blog-list-module--card2--QQVDY";
export var card3 = "blog-list-module--card3--psoJB";
export var excerpt = "blog-list-module--excerpt--C65Mi";
export var postsNav = "blog-list-module--posts-nav--KEx7c";
export var title = "blog-list-module--title--W1qx6";